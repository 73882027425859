import * as _shams2 from "has-symbols/shams";
var _shams = _shams2;
try {
  if ("default" in _shams2) _shams = _shams2.default;
} catch (e) {}
var exports = {};
var hasSymbols = _shams;

/** @type {import('.')} */
exports = function hasToStringTagShams() {
  return hasSymbols() && !!Symbol.toStringTag;
};
export default exports;